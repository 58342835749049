/* @media (max-width: 1023px) {
}
@media (max-width: 767px) {
}
@media (max-width: 480px) {
} */

/* font */
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
.notosanskr * { 
 font-family: 'Noto Sans KR', sans-serif;
}

/* nomal */
body, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'Noto Sans KR', sans-serif;
}

body * {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

/* library */
.bg-red {
  background-color: red;
}

.bg-gold {
  background-color: gold;
}

.bg-blue {
  background-color: blue;
}

.bg-gray {
  background: #f5f6f9;
}

/* custom */
:root {
  --header-width: 1400px;
  --header-height: 65px;
  --header-mobile-height: 50px;
  --detail-width: 1200px;
  --detail-right-width: 350px;
}

@media (max-width: 1023px) {
    :root {
        --header-height: 60px;
    }
}

:root {

  --page-width: 1200px;
  --page-padding-pc: 0 40px;
  --page-padding-tab: 0 30px;
  --page-padding-mo: 0 20px;
}

.block  {
  display: block !important;
}

.none {
  display: none !important;
}

.none-pc {
    display: none !important;
}
.none-mo {
    display: block !important;
}

.flex {
  display: flex !important;
}

.inline {
  display: inline !important;
}

.visible {
  visibility: visible !important;
}

.overflow-visible {
  overflow: visible !important;
}

.max-h100vh {
  max-height: 100vh !important;
}

.padding-top-20px {
  padding-top: 20px !important;
}

.underline {
  text-decoration: underline !important;
}

.bold {
    font-weight: bold !important;
}

.br-pc {
    display: block;
}
.br-mo {
    display: none;
}

@media (max-width: 1023px) {
    .br-pc {
        display: none !important;
    }    
    .br-mo {
        display: block !important;
    }
    .none-pc {
        display: block !important;
    }
    .none-mo {
        display: none !important;
    }   
}


/* ========================================================= */

.section-page__header-con {
    width: 100%;
    height: 450px;
    position: relative;
}
  
.section-page__hedaer-txt-con {
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
    width: 100%;
    text-align: center;
}

.section-page__hedaer-txt-con > div:nth-child(1) {
    font-size: 45px;
    letter-spacing: -1px;
    font-weight: 700;
    color: #fff;
}

.section-page__hedaer-txt-con > div:nth-child(2) {
    margin-top: 30px;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -1px;
    font-weight: 400;
    word-break: keep-all;
}


@media (max-width: 1023px) {
    .section-page__header-con {
        height: 350px;
    }

    .section-page__hedaer-txt-con {
        top: 33%;
        left: 50%;
        max-width: 330px;    
    }

    .section-page__hedaer-txt-con > div:nth-child(1) {
        font-size: 30px;
        /* font-weight: 700; */
    }

    .section-page__hedaer-txt-con > div:nth-child(2) {
        margin-top: 30px;
        font-size: 14px;
        line-height: 24px;
        /* font-weight: 400; */
    }
}


.bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.bg > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.section-one {
    padding: 100px 0;
}

.section-one__con {
    padding: var(--page-padding-pc);
    margin: 0 auto;
    width: 100%;
    max-width: var(--page-width);
}

.section__txt-subject {
  font-size: 20px;
  line-height: 20px;
  font-weight: 800;
  letter-spacing: -0.5px;
  color: #3692FF;
}

.section__txt-title {
  margin-top: 30px;
  font-size: 35px;
  line-height: 55px;
  font-weight: 800;
  letter-spacing: -0.5px;
}

.section__txt-sub {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: -0.5px;
}



@media (max-width: 1023px) {
    .section-one {
        padding: 65px 0;
    }

    .section-one__con {
        padding: var(--page-padding-mo);
        max-width: 600px;
    }

    .section__txt-con {
      text-align: center !important;
    }

    .section__txt-subject {
      font-size: 16px;
      line-height: 16px;
      font-weight: 700;
    }
    
    .section__txt-title {
      margin-top: 20px;
      font-size: 35px;
      line-height: 45px;
    }
}

/* ======================================================== */
/* Header  */

.header {
    height: var(--header-height);
    /* 평상시에 height를 초과한 서브메뉴가 안보이게 hidden */
    position: fixed;
    width: 100%;
    z-index: 500;
  }
  
  .header * {
      color: #fff;
  } 
  
  .header-changed {
      background-color: #fff;
    box-shadow: 0 1px 2px #cfcfcf,
                0 0px 3px #888888;
  }
  
  .header-changed * {
      color: #111;
  }
  
  
  .header__con {
    height: var(--header-height);
    max-width: var(--header-width);
    width: 100%;
    margin: 0 auto;
    padding: var(--page-padding-pc);
    display: flex;
    justify-content: space-between;
    color: #111;
    z-index: 300 !important;
  }
  
  .header__logo-con {
    width: 300px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .header__logo-con > li {
    height: 100%; 
    font-size: 23px;
    font-weight: 900;
    letter-spacing: -1px;
    line-height: 28px;  
  }
  
  .header__logo-con > li > a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .header__logo {
      height: 100%;
      display: flex;
      align-items: center;
  }
  
  .header__logo img {
    height: 40%;
    margin-right: 20px;
  }
  
  
  .header__category-con,
  .header__contact-wrap {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .header__category-con > li,
  .header__contact-wrap > li {
    height: 100%;
    /* width: 90px; */
    position: relative;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .header__dropdown {
    position: relative;
  }
  
  .header__dropdown > ul {
    display: none;
    position: absolute;
    top: 100%;
    left: -1;
    /* transform: translateX(-50%); */
    width: 250px;
    /* height: 45px; */
    background-color: #fff;
    border: 2px solid #e4e1e1;
    border-radius: 5px;
  }
  
  .header__dropdown:hover > a {
    margin-top: 5px;
    padding-bottom: 5px;
    border-bottom: 2px solid #748ec1;
  }
  
  .header__dropdown:hover > ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .header__dropdown:hover > ul > li > a {
    display: block;
    padding: 15px 0;
    font-size: 13px;
    font-weight: 400;
    color: #393939 !important;
    letter-spacing: -1px;
  }
  
  .header__hamburger {
    display: none;
  }

  
  .header-mo {
      display: none;
  }
  
  /* .header-block {
      height: 85px;
  } */
    
  
  @media (max-width: 1023px) {
    .header-unclick {
      height: 60px !important;
    }

    .header__con {
      height: 60px;
      display: block;
      padding: var(--page-padding-mo);
    }
  
    .header__logo-con {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  
    .header__logo img {
      margin-right: 10px;
    }
    
  
    .header__logo > a {
      font-size: 20px;
      vertical-align: bottom;
    }

    .header__logo > a > span {
      margin-top: 10px;
      /* margin-left: -15px; */
      font-size: 16px;
    }
  
    .header__hamburger {
      display: block;
    }
  
    .header__hamburger img {
      width: 24px;
    }
  
  
    .header__category-con,
    .header__contact-wrap {
      display: none;
    }

    .header-mo {
      display: block;
      position: fixed;
      width: 80%;
      height: 100vh;
      left: -110%;
      top: 0;
      z-index: 999;
      transition: left .4s ease-in;
      background-color: #fff;
    }
  
    .left-0 {
      left: 0;
      }
  
      .header-mo__category-con {
          margin-top: 50px;
      }
  
      .header-mo__category-con > li > a {
          width: 100%;
          border-bottom: 1px solid #d5d5d5;
      }
  
      .header-mo__category-con > li:last-child > a {
          border: none;
      }
  
      .header-mo__category-con > li > a {
          display: inline-block;
          padding: 18px 30px;
          font-size: 14px;
          font-weight: 400;
      }
  
      .header-mo__dropdown > ul {
        display: none;
      }
  
      .header-mo__dropdown:hover > ul {
        display: block;
      }
  
      .header-mo__dropdown > ul > li > a {
        display: inline-block;
        padding: 5px 30px;
        font-size: 14px;
        font-weight: 400;
        color: #4c4c4c;
  
      }
  
      .header__contact-btn-wrap {
          display: flex;
          justify-content: center;
          margin-top: 50px;
      }
  
      .header__contact-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
      }
      
      .header__contact-btn > a {
        display: inline-block;
        padding: 10px 30px;
        width: 180px;
        font-size: 15px;
        font-weight: 500;
        text-align: center;
      }
  
  
      .bg-gray {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          z-index: 888;
          background-color: rgba(0, 0, 0, 0.8);
      }
  
    .header .header__lang-btn {
      display: none;
    }
  
    .header__lang-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
      margin-right: 30px;
    }
  }
  

  .header-app-mo {
    display: none;
  }
  
  @media (max-width: 767px) {
    .header-app-mo {
      display: block;
      height: 55px;
      width: 100%;
      position: fixed;
      bottom: 0;
      z-index: 100;
      background: #fff;
      /* background: #f7f7f7; */
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      box-shadow:0px -2px 2px rgba(0,0,0,0.1)
    }
  
    .header-app-mo__menu-con {
      height: 100%;
      display: flex;
      align-items: center;
    }
  
    .header-app-mo__menu-item {
      flex: 1;
      display: flex;
      justify-content: center;
    }

    .header-app-mo__menu-item > a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        color: rgb(100,100,100);
        font-size: 12px;
        line-height: 12px;
        font-weight: 500;
    }

    .header-app-mo__menu-item > a span {
      color: #3692FF;
    }

    .header-app-mo__menu-item  li:first-child > img {
        width: 35px;
    }
  }
  

/* ======================================================== */

.intro-hero {
    width: 100%;
    height: 100vh;
}

.intro-hero__con {
  position: relative;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.intro-hero__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.intro-hero__bg-blue {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(168, 202, 254, 0.15);
}

.intro-hero__bg > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.intro-hero__txt-con {
  width: 35%;
}


.intro-hero__txt-title {
  font-size: 70px;
  line-height: 85px;
  letter-spacing: -1px;
  font-weight: 800;
  color: #fff;
  word-break: keep-all;
  letter-spacing: -0.5px;
}

.intro-hero__txt-sub {
  margin-top: 60px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.5px;
  font-weight: 400;
  word-break: keep-all;
  color: #fff;
}

.intro-hero__btn-wrap {
  margin-top: 60px;
  display: flex;
  align-items: center;
}

.intro-hero__btn-wrap > a {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 160px;
  height: 50px;
  background-color: #fff;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
}
.intro-hero__btn-wrap > a > img {
  margin-right: 10px;
}

.intro-hero__slick-con {
  max-width: 600px;
  width: 100%;
  aspect-ratio: 16/11;
  position: relative;
}

.intro-hero__slick {
  width: 100%;
  height: 100%;
}

.intro-hero__slick img {
  width: 100%;
  aspect-ratio: 16/11;
  object-fit: cover;
}

.intro-hero__slick .slick-dots {
  top: 10px; 
}

.intro-hero__slick .slick-dots li button:before,
.intro-hero__slick .slick-active button:before {
  color: #fff !important;
}

.intro-hero__slick-arrow-con {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.intro-hero__slick-arrow-con > div {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  box-sizing: border-box;
}

.intro-hero__slick-arrow-con > div:nth-of-type(1) {
  left: 20px;
}

.intro-hero__slick-arrow-con > div:nth-of-type(2) {
  right: 20px;
  text-align: right;
}

.intro-hero__slick-arrow-con > div > img {
  width: 20px;
}

/* .intro-hero__swiper {
  max-width: 600px;
  width: 100%;
  aspect-ratio: 16/11;
}

.intro-hero__swiper-slide > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.intro-hero__swiper-con .swiper-button-prev:after,
.intro-hero__swiper-con .swiper-button-next:after {
  color: #fff;
  font-size: 28px;
  font-weight: 900;
}

.intro-hero__swiper-con .swiper-pagination {
  top: 0 !important;
}

.intro-hero__swiper-con .swiper-pagination-bullet {
  width: 10% !important;
  height: 5px !important;
  border-radius: 2px;
  opacity: 1 !important;
}

.intro-hero__swiper-con .swiper-pagination-bullet-active {
  background-color: #fff !important;
} */

@media (max-width: 1023px) {
  .intro-hero {
    /* width: 100%; */
    height: calc(100vh - var(--header-mobile-height));
  }

  .intro-hero__con {
    /* position: relative; */
    /* height: 100%; */

    /* display: flex; */
    flex-direction: column-reverse;
    justify-content: center;
    /* align-items: center; */
  }

  .intro-hero__bg {
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; */
  }

  .intro-hero__bg-blue {
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(23, 67, 121, 0.3); */
  }

  .intro-hero__bg > img {
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; */
  }

  .intro-hero__txt-con {
    width: 100%;
    text-align: center;
  }


  .intro-hero__txt-title {
    margin-top: 30px;
    font-size: 40px;
    line-height: 40px;
    /* letter-spacing: -1px; */
    /* font-weight: 800; */
    /* color: #fff; */
    /* word-break: keep-all; */
    /* letter-spacing: -0.5px; */
  }

  .intro-hero__txt-sub {
    margin-top: 30px;
    font-size: 16px;
    line-height: 20px;
    /* letter-spacing: -0.5px; */
    /* font-weight: 400; */
    /* word-break: keep-all; */
  }

  .intro-hero__btn-wrap {
    /* display: none; */
    /* margin-top: 60px; */
    /* display: flex; */
    /* align-items: center; */
    justify-content: center;
  }

  .intro-hero__swiper-con {
    margin-top: -30px;
  }

  .intro-hero__swiper {
    max-width: 320px;
    width: 100%;
    aspect-ratio: 1/1;
  }

  .intro-hero__swiper-slide {
    height: 100%;
  }

  .intro-hero__swiper-slide > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .intro-hero__swiper .swiper-button-prev:after,
  .intro-hero__swiper .swiper-button-next:after {
    color: #fff;
    font-size: 28px;
    font-weight: 900;
  }

  .intro-hero__swiper .swiper-pagination {
    top: 0 !important;
  }

  .intro-hero__swiper .swiper-pagination-bullet {
    width: 10% !important;
    height: 5px !important;
    border-radius: 2px;
    opacity: 1 !important;
  }

  .intro-hero__swiper .swiper-pagination-bullet-active {
    background-color: #fff !important;
  }
      
}
/* ======================================================== */

.intro-book__con {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

.intro-book__txt-con {
  margin-left: 200px;
}

.intro-book__txt-sub {
  font-size: 20px;
  line-height: 20px;
  font-weight: 800;
  letter-spacing: -0.5px;
  color: #3692FF;
}

.intro-book__txt-title {
  /* margin-top: 30px; */
  font-size: 35px;
  line-height: 55px;
  font-weight: 900;
  letter-spacing: -0.5px;
  color: #3692FF;
}

.intro-book__txt-detail {
    margin-top: 80px;
    font-size: 25px;
    line-height: 50px;
    font-weight: 300;
    letter-spacing: -0.5px;
}

.intro-book__btn-wrap {
    margin-top: 80px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.intro-book__btn-wrap > a {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 230px;
    height: 50px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.5px;
    cursor: pointer;
    box-shadow: 0 0 7px #97bfd8;
}
.intro-book__btn-wrap > a > img {
    margin-right: 10px;
    height: 20px;
    aspect-ratio: 50/52;
}

.intro-book__img-wrap {
    height: 650px;
}

.intro-book__img-wrap > img {
    height: 100%;
    object-fit: contain;
}

@media (max-width: 1023px) {
  .intro-book__con {
    display: flex;
    flex-direction: column;
  }

  .intro-book__txt-con {
    margin-left: 0;
    text-align: center;
  }

  .intro-book__txt-sub {
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
  }

  .intro-book__txt-title {
    margin-top: 50px;
    font-size: 35px;
    line-height: 50px;
  }

  .intro-book__txt-detail {
    margin-top: 15px;
    font-size: 18px;
    line-height: 26px;
    /* font-weight: 300; */
    /* letter-spacing: -0.5px; */
}

  .intro-book__btn-wrap {
      margin-top: 30px;
      justify-content: center;
  }

  .intro-book__btn-wrap > a {
    font-size: 14px;
}

  .intro-book__img-wrap {
    margin-top: 15px;
    height: 250px;
  }
}

/* ======================================================== */

.intro-partners__swiper-con {
  margin-top: 60px;
}

.intro-partners__swiper-con img {
  width: 100%;
  aspect-ratio: 16/10;
  object-fit: contain;
}

@media (max-width: 1023px) {
  .intro-partners__swiper-con {
    margin-top: 0;
  }

  .intro-partners__swiper-con img {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
}

/* ======================================================== */

.intro-board .section__txt-con {
  /* text-align: right; */
}

.intro-board__table {
  margin-top: 60px;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* table 내 말줄임 (...) 기능 (1/4) */
}

.intro-board__thead > tr > th {
  padding: 12px 0;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: -0.5px;
  text-align: left;
  border-top: 2px solid #424242;
  border-bottom: 2px solid #424242;
}

.intro-board__thead > tr > th:nth-child(1) {
  width: 20%;
  padding-left: 30px;
}
.intro-board__thead > tr > th:nth-child(2) {
  width: 100%;
}
.intro-board__thead > tr > th:nth-child(3) {
  padding-right: 30px;
  width: 15%;
  font-size: 14px;
  line-height: 14px;
  font-weight: 300;
  text-align: right;
}


/* 표 하단 선 */
.intro-board__tbody > tr:last-child {
  border-bottom: 2px solid #424242;
}


.intro-board__tbody {
  position: relative;
}


.intro-board__tbody > tr {
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.intro-board__tbody > tr > td {
  padding: 15px 0;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: -1px;
  vertical-align: top;

  /* table 내 말줄임 (...) 기능 (2~4/4) */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.intro-board__tbody > tr > td:nth-child(1) {
  padding-left: 30px;
}
.intro-board__tbody > tr > td:nth-child(3) {
  padding-right: 30px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  text-align: right;
  color: #8e8e8e;
}

.intro-board__content-hide {
  display: none;
}

.intro-board__content-hide > a {
  display: block;
  font-size: 14px;
  font-weight: 300;
  text-decoration: underline;
  text-align: right;
  color: #042dc3;
}

.intro-board__tbody pre {
  /* display: none; */
  padding-top: 15px;
  font-size: 16px;
  line-height: 160%;
  font-weight: 400;
  letter-spacing: -0.5px;


  /* pre 초기화 */
  margin: 0;
  font-family: 'Noto Sans KR', sans-serif;
  /* pre 태그의 내용이 삐져나옴 방지 */
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.intro-board__tbody pre p {
  margin: 0; /* line-height의 정상적인 적용을 위해 반드시 기입해야 함 */
}

.intro-board__tbody pre img {
  max-width: 100%;
}


.intro-board__title-after {
  font-weight: 600;
}
.intro-board__content-after {
  display: block !important;
}



.intro-board__more {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.intro-board__more > a {
  padding: 15px 35px;
  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  letter-spacing: -1px;

  border: 1px solid #d0d0d0;
  border-radius: 30px;
  cursor: pointer;
}


@media (max-width: 1023px) {  
  .intro-board__table {
    margin-top: 40px;
  }
  
  .intro-board__thead > tr > th {
    padding: 10px 0;
    font-size: 16px;
    line-height: 16px;
  
    border-top: 3px solid #424242;
    border-bottom: 3px solid #424242;
  }
  
  .intro-board__thead > tr > th:nth-child(1) {
    width: 25%;
    padding-left: 10px;
  }
  .intro-board__thead > tr > th:nth-child(3) {
    padding-right: 10px;
    width: 35%;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    text-align: left;
  }
  
  
  /* 표 하단 선 */
  .intro-board__tbody > tr:last-child {
    border-bottom: 3px solid #424242;
  }
    
  .intro-board__tbody > tr > td {
    padding: 12px 0;
    font-size: 14px;
    line-height: 14px;
  }
  
  .intro-board__tbody > tr > td:nth-child(1) {
    padding-left: 10px;
  }
  .intro-board__tbody > tr > td:nth-child(3) {
    padding-right: 10px;
    font-size: 13px;
    line-height: 13px;
    text-align: left;
  }

  .intro-board__tbody pre {
    padding-top: 10px;
    font-size: 12px;
    /* line-height: 180%; */
  }
  
  .intro-board__more {
    margin-top: 30px;
  }
  
  .intro-board__more > a {
    padding: 10px 25px;
    display: flex;
    align-items: center;
  }
}

/* ======================================================== */

.introduction-hero {
  position: relative;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.introduction-hero__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.introduction-hero__bg-blue {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, #165676 0%, #3486B3 50%, #165676 100%);
}

.introduction-hero__bg > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.introduction-hero__con {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* .introduction-hero__bg {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  overflow: hidden;
} */

.introduction-hero__txt-con {
    padding-top: 35vh;
    text-align: center;
}

.introduction-hero__txt-sub {
    margin-top: 50px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.5px;
    font-weight: 400;
    word-break: keep-all;
    color: #fff;
}

.introduction-hero__txt-title {
  font-size: 60px;
  line-height: 75px;
  letter-spacing: -1px;
  font-weight: 800;
  color: #fff;
  word-break: keep-all;
  letter-spacing: -0.5px;
}

.introduction-hero__txt-title-mo {
    display: none;
}

.introduction-hero__btn-wrap {
    display: none;
}

.swiper-wrapper {
    transition-timing-function: linear !important; /* Slides flow softly without stop */
}

.introduction-hero__swiper-con {
    padding-bottom: 5vh;
 }
 
.introduction-hero .swiper-item {
    padding: 22px;
    width: 320px;
    aspect-ratio: 444/251;

    display: flex;
    justify-content: space-around;

    background-color: rgba(255,255,255,0.5);
    border-radius: 10px;
}

.introduction-hero .swiper-item > div {
    height: 100%;
    aspect-ratio: 1/1;

    background-color: rgba(255,255,255);
    border-radius: 5px;
}

.introduction-hero .swiper-item > ul {
    margin-left: 20px;

    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.5px;
}

.introduction-hero .swiper-item > ul > li:nth-child(2) {
    margin-top: 10px;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    word-break: keep-all;
}
  


@media (max-width: 1023px) {
    .introduction-hero {
        height: 100vh;
    }
    .introduction-hero__txt-con {
        padding-top: 20vh;
    }
    .introduction-hero__txt-title {
        display: none;
    }

    .introduction-hero__txt-title-mo {
        display: block;
        font-size: 45px;
        line-height: 55px;
        letter-spacing: -1px;
        font-weight: 800;
        color: #fff;
        word-break: keep-all;
        letter-spacing: -0.5px;
    }
    .introduction-hero__txt-title-mo > span {
      display: inline-block;
      margin-bottom: 20px;
      font-size: 22px;
      line-height: 22px;
      font-weight: 400;
    }
    .introduction-hero__txt-sub {
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
        letter-spacing: -1px;
        color: #fff;
    }
    .introduction-hero__txt-sub > span {
        font-weight: 700;
    }
    .introduction-hero__btn-wrap {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .introduction-hero__btn-wrap > a {
        display: flex;
        justify-content: center;
        align-items: center;
    
        width: 220px;
        height: 45px;
        background-color: #0066A5;
        border-radius: 15px;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.5px;
    }
    .introduction-hero__btn-wrap > a > img {
        margin-right: 10px;
    }

    .introduction-hero__swiper-con {
        padding-bottom: 15vh;
     }
     
    .introduction-hero .swiper-item {
        padding: 15px;
        width: 150px;
        aspect-ratio: 444/280;
    }
    
    .introduction-hero .swiper-item > ul {
        margin-left: 10px;
    
        font-size: 12px;
    }
    
    .introduction-hero .swiper-item > ul > li:nth-child(2) {
        margin-top: 5px;
        font-size: 6px;
        line-height: 12px;
    }
      
}
@media (max-width: 767px) {
}
@media (max-width: 480px) {
}

/* ======================================================== */
.introduction-years__con {
}

.introduction-years__txt-con {
  position: relative;
}

.introduction-years__header-title {
  font-size: 35px;
  line-height: 35px;
  font-weight: 800;
  letter-spacing: -0.5px;
  color: #3692FF;
}

.introduction-years__txt-title {
  margin-top: 50px;
  font-size: 25px;
  line-height: 60px;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.introduction-years__txt-title > span {
  color: #3692FF;
}


@media (max-width: 1023px) {
  .introduction-years__txt-con {
    width: 100%;
    text-align: center;
  }

  .introduction-years__header-title {
    /* font-size: 16px; */
    /* line-height: 16px; */
    font-weight: 700;
  }

  .introduction-years__con {
      flex-direction: column;
      align-items: flex-start;
  }

  .introduction-years__txt-title {
    /* margin-top: 30px; */
    font-size: 18px;
    line-height: 40px;
    font-weight: 600;
    letter-spacing: -1px;
    word-break: keep-all;
    text-align: left;
  }
  
  
  .introduction-years__img-wrap {
      margin-top: 30px;
      width: 100%;
  }
  
  .introduction-years__img-wrap > img {
      width: 100%;
      /* object-fit: contain; */
      object-fit: cover;
      /* border: 2px solid #dfdfdf; */
      border-radius: 0px;    
  }
}

/* ======================================================== */

.introduction-years__content-con {
  margin: 0 auto;
  margin-top: 50px;
  max-width: 900px;
  width: 100%;
}

.introduction-years__content-item {
  padding: 15px 0;
  width: 100%;
  display: flex;
}

.introduction-years__content-item:not(:first-child) {
  border-top: 1px solid #dfdfdf;
}

.introduction-years__content-item > li {
  width: 150px;
  font-size: 35px;
  line-height: 30px;
  font-weight: 600;
}

.introduction-years__content-item > ul > li {
  display: flex;
  padding: 3px 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
}

.introduction-years__content-item > ul > li p {
  margin: 0;
}

@media (max-width: 1023px) {
  .introduction-years__content-con {
    /* margin: 0 auto; */
    margin-top: 30px;
    /* max-width: 900px; */
    /* width: 100%; */
  }
  
  .introduction-years__content-item {
    padding: 10px 0;
    /* width: 100%; */
    /* display: flex; */
  }
  
  .introduction-years__content-item:not(:first-child) {
    /* border-top: 1px solid #dfdfdf; */
  }
  
  .introduction-years__content-item > li {
    width: 80px;
    font-size: 20px;
    line-height: 20px;
    /* font-weight: 600; */
  }
  
  .introduction-years__content-item > ul > li {
    display: flex;
    padding: 3px 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
  }
  
  .introduction-years__content-item > ul > li p {
    margin: 0;
  }
  
}

/* ======================================================== */

.introduction-map__content-con {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
}

.introduction-map__map-wrap {
    width: 70%;
    height: 400px;
    background-color: #afafaf;
}

.introduction-map__txt-con {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.introduction-map__txt-con > ul:nth-child(2) {
    margin-top: 60px;
}

.introduction-map__txt-con > ul > li {
    display: flex;
}

.introduction-map__txt-con > ul > li:nth-child(1) {
    font-size: 30px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: -0.5px;
}

.introduction-map__txt-con > ul > li:nth-child(2) {
    padding-top: 20px;
    padding-left: 20px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -0.5px;
}

@media (max-width: 1023px) {
    .introduction-map .section__txt-title {
      line-height: 75px;
    }
    .introduction-map__content-con {
        margin-top: 50px;
        flex-direction: column;
    }
    .introduction-map__map-wrap {
        width: 100%;
    }
    .introduction-map__txt-con {
        margin-top: 30px;
    }

    .introduction-map__paste > a {
        padding: 5px 6px;
    
        font-size: 12px;
        line-height: 12px;
    
        border: 2px solid #afafaf;
        border-radius: 30px;
    }
    
    .introduction-map__txt-con > ul:nth-child(2) {
        margin-top: 30px;
    }
    
    .introduction-map__txt-con > ul > li:nth-child(1) {
        font-size: 25px;
        line-height: 25px;
    }
    
    .introduction-map__txt-con > ul > li:nth-child(2) {
        padding-top: 20px;
        padding-left: 0;
        font-size: 16px;
        line-height: 22px;
    } 
}

/* ======================================================== */

.product__img-con {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
}

.product__img-con li {
  margin: 0 12px;
  width: calc((100% - 120px) / 5);
  
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px; /* adjusting position of pagination*/
  cursor: pointer;
}

.product__img-con li > img {
  width: 100%;
  aspect-ratio: 16/12;
  object-fit: cover;

  box-shadow: 2px 2px 6px #afafaf;
  border-radius: 20px;
}

.product__img-con li > span:nth-of-type(1) {
  margin-top: 20px;
  display: block;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: -0.5px;
}

.product-after {
  font-weight: 600 !important;
  text-decoration: underline;
}


.product__img-con li > span:nth-of-type(2) {
  margin-top: 10px;
  display: block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: -0.5px;
  color: #afafaf;

  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: keep-all;
}

.product__img-con .swiper-button-prev,
.product__img-con .swiper-button-next {
  top: 32%;
}

.product__img-con .swiper-button-prev:after,
.product__img-con .swiper-button-next:after {
  color: #e6e6e6;
  font-size: 28px;
  font-weight: 900;
}

.product__detail-con {
  margin: 0 auto;
  margin-top: 10px;
  max-width: 800px;
  width: 100%;
  border-top: 1px solid #9f9f9f;
}

.product__detail-con > pre * {
  margin: 0; 
}

.product__detail-con > pre {
  width: 100%;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: -0.5px;
  font-family: 'Noto Sans KR', sans-serif !important;

  /* pre 초기화 */
  margin: 0;
  font-family: 'Noto Sans KR', sans-serif;
  /* pre 태그의 내용이 삐져나옴 방지 */
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.product__detail-con > pre > p {
  width: 100%;
}

.product__detail-con > pre * {
  max-width: 100%;
}


/* .product__detail-con > li {
  margin-top: 15px;
  display: flex;
}

.product__detail-con > li > div:nth-of-type(1) {
  display: flex;
  justify-content: space-between;
  width: 150px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.product__detail-con > li > div:nth-of-type(1) span {
  margin-right: 15px;
}

.product__detail-con > li > div:nth-of-type(1)::after {
  content: ":";
}

.product__detail-con > li > div:nth-of-type(2) {
  padding-left: 15px;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: -0.5px;
  color: #606060;
}

.product__detail-con > li > div:nth-of-type(2) > p {
  margin: 0;
} */


@media (max-width: 1023px) {
  .product__img-con {
    margin-top: 30px;
  }
  
  .product__img-con li {
    width: calc((100% - 50px) / 2);
  }
  
  .product__img-con li > img {
    border-radius: 10px;
  }
  
  .product__img-con li > span:nth-of-type(1) {
    font-size: 16px;
    line-height: 24px;
  }

  .product__detail-con > pre {
    font-size: 16px;
    line-height: 24px;
  }

  /* .product__detail-con > li > div:nth-of-type(1) {
    width: 150px;
    font-size: 16px;
    line-height: 16px;
  }
  
  .product__detail-con > li > div:nth-of-type(1) span {
    margin-right: 15px;
  }
  
  .product__detail-con > li > div:nth-of-type(1)::after {
    content: ":";
  }
  
  .product__detail-con > li > div:nth-of-type(2) {
    font-size: 14px;
    line-height: 20px;
  } */  
}

/* ======================================================== */

.service__btn-con {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.service__btn-con select {
  padding: 17px 25px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  border: 1px solid #cccccc;
  border-radius: 30px;
  background-color: #fff !important;
}

/* product */
.service__btn-items {
  padding: 0 25px;
  display: flex;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;

  /* padding: 0 25px;
  display: flex;
  border: 1px solid #cccccc;
  border-radius: 30px; */
}

.service__btn-items > ul {
  display: flex;
  justify-content: center;
}

.service__btn-items > ul > li {
  padding: 20px 25px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: -1px;
  cursor: pointer;
}

.service__btn-after {
  font-weight: 700 !important;
}

@media (max-width: 1023px) {
  .service__btn-con {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .service__btn-con select {
    padding: 12.25px 15px;
    font-size: 12px;
    line-height: 12px;
    /* font-weight: 700; */
    /* border: 1px solid #cccccc; */
    /* border-radius: 30px; */
    /* background-color: #fff !important; */
  }

  .service__btn-items {
    padding: 0 10px;
    flex-direction: column;

    /* padding: 0 15px;
    border-radius: 20px;
    flex-direction: column; */
  }
  
  .service__btn-items > ul > li {
    padding: 10px 20px;
    font-size: 12px;
    line-height: 12px;
  }
  
  .service__btn-after {
    font-weight: 700 !important;
  }

}


/* ======================================================== */

.admin {
    position: relative;
    min-height: 100vh;
  }
  
  .admin > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* ======================================================== */
  
  .admin-signin__con {
    position: absolute;
    padding: 0 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 340px;
    width: 100%;
    height: 500px;
    background-color: #f5f4f4;
    box-shadow: 0px 0px 10px #dedede;
    border-radius: 20px;
  
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
  }
  
  .admin-signin__con > img {
    margin: 0 auto;
    max-width: 100px;
    width: 100%;
    object-fit: cover;
  }
  
  .admin-signin__input-item {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .signup2__answer-input {
    margin-top: 60px;
    padding: 10px 0;
    width: 100%;
    border: none;
    border-radius: 10px;
    outline: none;
    font-size: 18px;
    color: #fafafa;
    text-indent: 10px;
    background-color: #afafaf;
  }
  
  .admin-signin__btn {
    margin-top: 15px;
    padding: 10px 0;
    width: 100%;
    border-radius: 10px;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
  
    display: flex;
    justify-content: center;
    /* background-image: linear-gradient(to right, #5c5c5c 0%, #3d3d3d 100%); */
    background-image: linear-gradient(to right, #6f9fe1 0%, #3c6fc1 100%);
    cursor: pointer;
  }
  
  @media (max-width: 1023px) {
    .admin-signin__con {
      max-width: 300px;
      height: 450px;
    }
  }
  
  /* ======================================================== */
  
  .admin-list__con {
    position: absolute;
    padding: 50px 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 800px;
    width: 100%;
    min-height: 600px;
    background-color: #f5f4f4;
    box-shadow: 0px 0px 10px #dedede;
    border-radius: 20px;
  
    display: flex;
    flex-direction: column;
    /* align-items: center; */
  }
  
  .admin-list__btn-con {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-left: -1px;
  }
  
  .admin-list__btn-con > div {
    margin: 1px 1px;
    width: calc((100%)/3 - 2px);
    height: 50px;
  
    display: flex;
    justify-content: center;
    align-items: center;
  
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  
    background-image: linear-gradient(to right, #acabb1 0%, #acabb1 100%);
    cursor: pointer;
  }
  
  .admin-list__btn-after {
    background-image: linear-gradient(to right, #6f9fe1 0%, #3c6fc1 100%) !important;
  }
  
  
  @media (max-width: 1023px) {
    .admin-list__con {
      padding: 25px 25px;
      max-width: 350px;
      min-height: 500px;
    }
  
    .admin-list__btn-con > div {
      height: 40px;
      font-size: 12px;
    }
  }

/* ======================================================== */

.admin-list__title {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    font-size: 22px;
    font-weight: 600;
  }
  
.admin-list__more {
    position: relative;
    margin-right: 10px;
    font-size: 15px;
    font-weight: 400;
  }
  
.admin-list__more::after {
    position: absolute;
    left: 100%;
    top: 39%; 
    content: '';
    width: 7px; /* 사이즈 */
    height: 7px; /* 사이즈 */
    border-top: 1px solid #000; /* 선 두께 */
    border-right: 1px solid #000; /* 선 두께 */
    transform: rotate(45deg); /* 각도 */
  }

  .admin-list__more > label {
    cursor: pointer;
  }
  
  .admin-list__table {
    margin-top: 15px;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* table 내 말줄임 (...) 기능 (1/4) */
  }
  
  .admin-list__thead > tr > th {
    text-align: left;
    font-size: 16px;
    line-height: 36px;
    font-weight: 500;
    color: #646464;
    border-bottom: 2px solid rgba(0,0,0,0.3);
  }
  
  /* 표 하단 선 */
  .admin-list__tbody > tr:last-child {
    border-bottom: 2px solid rgba(0,0,0,0.3);
  }
  
  .admin-list__th01 {
    width: 23%;
  }
  .admin-list__th02 {
    width: 100%;
  }
  .admin-list__th03 {
    width: 10%;
  }
  
  .admin-list__tbody {
    position: relative;
  }
  

  .admin-list__tbody > tr {
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }
  
  .admin-list__tbody > tr > td {
    font-size: 16px;
    line-height: 40px;
    font-weight: 400;
    letter-spacing: -1px;
  
  
    /* table 내 말줄임 (...) 기능 (2~4/4) */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .admin-list__tbody > tr > td:nth-child(2) {
    font-weight: 500;
  }
  
  .admin-list__tbody > tr > td > a {
    cursor: pointer;
  }
  
  .board__page-number {
    display: flex;
    justify-content: center;
  }
  
  .board__page-number > div {
    margin-top: 20px;
    margin-right: 10px;
  
    font-size: 15px;
    cursor: pointer;
  }

  
  
  
  @media (max-width: 1023px) {
    .intro-board__title {
      margin-top: 30px;
    }
  
    .admin-list__table {
      /* display: none; */
    }
    .admin-list__th01 {
      width: 35%;
    }
    .admin-list__th02 {
      width: 100%;
    }
    .admin-list__th03 {
      width: 15%;
    }
    .admin-list__thead > tr > th {
      font-size: 12px;
      line-height: 22px;
    }
    .admin-list__tbody > tr > td {
      font-size: 12px;
      line-height: 32px;
    }
  }

/* ======================================================== */

.admin-list__img-con {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-top: 2px solid #afafaf;
}

.admin-list__img-wrap {
  margin: 10px 40px;
  width: 120px;
}

.admin-list__img-wrap > img {
  width: 100%;
  aspect-ratio: 16/12;
  object-fit: contain;
}

.admin-list__img-wrap-txt {
  display: flex;
  justify-content: space-around;
}

@media (max-width: 1023px) {
  .admin-list__img-wrap {
    margin: 10px 10px;
    width: calc(25%);
  }
  
  .admin-list__img-wrap > img {
    width: 100%;
    aspect-ratio: 16/12;
    object-fit: contain;
  }


}

/* ======================================================== */

.admin-write__con {
    position: absolute;
    padding: 30px 30px;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 900px;
    width: 100%;
    min-height: 600px;
    background-color: #f5f4f4;
    box-shadow: 0px 0px 10px #dedede;
    border-radius: 20px;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
  }
  
  @media (max-width: 1023px) {
    .admin-write__con {
      padding: 20px 25px;
      max-width: 350px;
      min-height: 500px;
    }
  }
  
  /* =========================================================== */

  .react-quill > * {
    font-family: 'Noto Sans KR', sans-serif !important;
  }
  
  .board-write__con {
    padding: var(--page-padding-mo);
    margin: 0 auto;
    width: 100%;
    /* max-width: var(--page-width); */
    max-width: 800px;
  }
  
  .board-write__board-name {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.5px;
    
    display: flex;
    justify-content: space-between;
  }
  
  .board-write__header{
    border-bottom: 1px solid #d3d1d1;
  }

  .board-write__select-con {
    margin-top: 15px;

    display: flex;
    justify-content: space-between;
  }

  .board-write__select-con select {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
    color: #4f4f4f !important;
    border: 1px solid #5b5b5b !important;
    border-radius: 5px;
    background-color: #f5f4f4 !important;
  }
  
  .board-write__new-menu {
    width: 150px;
    font-size: 15px;
    font-weight: 400;
    color: #434242;
    border: 2px solid #a5a3a3;
    white-space: pre;

    color: #4f4f4f !important;
    background-color: #f5f4f4 !important;
    font-family: 'Noto Sans KR', sans-serif;
    resize: none;
  }
  
  .board-write__header-title{
    margin: 15px 0;
    color: #434242;
    border: 2px solid #a5a3a3;
  }
  
  .board-write__content-con {
    padding-top: 15px;
  }
  
  .board-write__content-item {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 400;
    color: #434242;
    border: 2px solid #a5a3a3;
    white-space: pre;
  }
  
  .board-write__header-input,
  .board-write__content-input{
    padding-left: 15px;
    min-width: 95%;
    border: none;
    outline: none;
    font-size: 15px;
    font-family: 'Noto Sans KR', sans-serif;
    resize: none;
    background-color: transparent;
  }
  
  .board-write__content-input{
    min-height: 300px;
  }
  
  .board-write__content-files label {
    margin-top: 3px;
    margin-right: 10px;
    padding: 2px 5px;
  
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.5px;
    color: #4e4e4e;
  
    background-color: #e9e7e7;
    border: 1px solid #111;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .board-write__content-files input {
    display: none;
  }
 
  .board-write__content-files span {
    margin-right: 10px;
  }

  .board-write__btn-submit{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .board-write__btn-submit label {
    margin-right: 5px;
  }
  
  .board-write__btn-submit > span{
    display: flex;
    justify-content: center;
    align-items: center;
  
    width: 80px;
    height: 40px;
  
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  
    background-image: linear-gradient(to right, #6f9fe1 0%, #3c6fc1 100%);
    border-radius: 10px;
    cursor: pointer;
  }
  
  .board-write__btn-before > span {
    background-color: #afafaf;
    box-shadow: none;
    cursor: not-allowed;
  }
  
  
  
  @media (max-width:834px) {
    .board-write {
      padding: 20px 0;
    }
  
    .board-write__header-title{
      margin-top: 10px;
      font-size: 20px;
    }
    
    .board-write__content-con {
      padding-top: 15px;
    }
  
    .board-write__content-item {
      font-size: 15px;
    }
  
    .board-write__btn-submit{
      margin-top: 10px;
    }
    
    .board-write__btn-submit > span{
      width: 70px;
      height: 35px;
    
      font-size: 13px;
  
      border-radius: 7px;
    }
  }

  /* ========================================================= */

.footer {
  padding: 65px 0;
  background: #efefef;
}

.footer__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  width: 100%;
  max-width: var(--page-width);

  display: flex;
  justify-content: space-between;
  position: relative;
}

.footer__info-con {
  width: 45%;
}

.footer__info-name {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.footer__info-con > li {
  display: flex;
}

.footer__info-con > li > div:nth-child(1) {
  width: 150px;
}

.footer__info-con > li > div {
  margin: 2px 0;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: -0.5px;
}

.footer__menu-con {
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.footer__menu-con > li > div:not(:nth-child(1)) {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 12px;
}

.footer__menu-con > li > div:nth-child(1) {
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
}

.footer__menu-con > li > a {
  cursor: pointer;
}

.footer__law-con {
  display: flex;
  position: absolute;
  right: 40px;
  bottom: 0px;
  font-size: 13px;
}

@media (max-width: 1023px) {
  .footer__con {
    padding: var(--page-padding-pc);
  
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  
  .footer__info-con {
    margin-top: 20px;
    width: 100%;
  }
  
  .footer__info-name {
    margin-bottom: 10px;
    /* font-size: 16px; */
    /* line-height: 16px; */
    /* font-weight: 700; */
    /* letter-spacing: -0.5px; */
  }
  
  .footer__info-con > li {
    /* display: flex; */
  }
  
  .footer__info-con > li > div:nth-child(1) {
    width: 100px;
  }
  
  .footer__info-con > li > div {
    /* margin: 2px 0; */
    font-size: 10px;
    line-height: 16px;
    /* font-weight: 400; */
    /* letter-spacing: -0.5px; */
  }

  .footer__menu-con {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .footer__menu-con > li {
    margin-top: 20px;
  }
  
  .footer__menu-con > li > div:not(:nth-child(1)) {
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 12px;
  }
  
  .footer__menu-con > li > div:nth-child(1) {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 16px;
    /* font-weight: 700; */
  }
  
  .footer__menu-con > li > a {
    cursor: pointer;
  }

  .footer__logo-con {
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .footer__logo-con > li {
    height: 100%; 
    font-size: 23px;
    font-weight: 900;
    letter-spacing: -1px;
    line-height: 28px;  
  }
  .footer__logo-con > li > span {
    display: block;
  }
  
  .footer__logo {
      height: 100%;
      /* display: flex; */
      /* align-items: center; */
  }
  
  .footer__logo img {
    height: 40%;
    margin-right: 20px;
  }

  .footer__law-con {
      bottom: 20px;
  }
}

/* ======================================================== */
/* react-quill CSS (필수) */

/* .react-quill {
  width: 100%;
} */

.ql-align-left {
  text-align: left !important;
}
.ql-align-center {
  text-align: center !important;
}
.ql-align-right {
  text-align: right !important;
}
.intro-board__tbody pre a,
.product__detail-con pre a {
  color: #0066A5 !important;
  text-decoration: underline;
}

/* -------------------------------------------------  */
/* 맨 밑에 있어야 css가 잘 먹힘 */

.up--start {
  opacity: 0;  
  transform: translateY(30px);
}

.up--end1 {
  opacity: 1;  
  transform: translateY(0px);
  transition : all 0.5s;
}

/* ========================================================= */

.license__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  width: 100%;
  max-width: var(--page-width);
} 

.license__grid-con { 
  margin: 100px 0;
  display: flex;
  flex-wrap: wrap;
}

.license__grid-item {
  margin: 30px 10px 30px 10px;
  width: calc( (100% - 2 * 20px) / 2 );
  display: flex;
  flex-direction: column;
  /* cursor: pointer; */
  position: relative;
}

.license__source-name {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -1px;
  color: #428ef9;
  cursor: pointer;
}

.license__source-url {
  letter-spacing: -1px;
  opacity: 0.7;
  font-weight: 400;
  color: darkblue;
  text-decoration: underline;
}

.license__source-type {
  letter-spacing: -1px;
  opacity: 0.7;
}


.license__source-writer {
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 22px;
}

.license__txt-item {
  padding: 30px 0;
  border-top: 1px solid #afafaf;
}

.license__txt-title {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
}

.license__txt-sub {
  margin-top: 30px;
  font-size: 14px;
  font-weight: 300;
  color: #222222;
}


@media (max-width: 1023px) {
  .license__con {
    padding: var(--page-padding-tab);
  } 

  .license__txt-sub {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .license__con {
    padding: var(--page-padding-mo);
  }

  .license__grid-item {
    margin: 15px 0px 15px 0px;
    margin-bottom: 15px;
    width: calc( (100% - 2 * 10px) / 2 );
  }

  .license__grid-item:nth-child(2n-1) {
    margin-right: 20px;
  }

  .license__source-name {
    font-size: 18px;
  }

  
  .license__source-type {
    font-size: 15px;
  }

  .license__source-writer {
    font-size: 15px;
    font-weight: 400;
  }

 
}
@media (max-width: 480px) {
}

